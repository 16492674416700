<!--
Provides a Recaptcha v2 element

Docs: https://developers.google.com/recaptcha
 -->
<script setup lang="ts">import { toRef as _toRef, computed as _computed, ref as _ref } from 'vue';

import { useFormInput } from '@consumer/services/form'
import { VueRecaptcha } from 'vue-recaptcha'
import { union } from '@shared/array'
import { presence } from '@shared/object'
import { requiredValidation } from '@shared/validation'
import { usePage } from '@consumer/composables/sharedData'
import { isTest } from '@shared/environment'

type RecaptchaProps = { recaptchaInvisibleSiteKey: string; recaptchaCheckboxSiteKey: string }

const __$temp_1 = (usePage<RecaptchaProps>()),
  props = _toRef(__$temp_1, 'props');

const __$temp_2 = (props.value),
  recaptchaInvisibleSiteKey = _toRef(__$temp_2, 'recaptchaInvisibleSiteKey'),
  recaptchaCheckboxSiteKey = _toRef(__$temp_2, 'recaptchaCheckboxSiteKey');

withDefaults(defineProps<{
  // The name of the field, which will be used to retrieve a value from a parent form.
  field?: string
}>(), { field: 'recaptcha', })

const { form, formData } = useFormInput(__props.field)

const slots = defineSlots()
const invisible = _computed(() => Boolean(slots.default))

// Errors that were gathered from the input validations.
const validationErrors = _computed(() =>
  invisible.value || requiredValidation.isValueValid(formData.value)
    ? []
    : ['Please complete the reCAPTCHA.'],
)

watch(() => validationErrors.value.length, (hasErrors) => {
  if (hasErrors)
    form.onInputErrors(__props.field, validationErrors.value)
  else
    form.onInputValid(__props.field)
}, { immediate: true })

const errors = _computed(() => form.displayErrors.value && presence(union(validationErrors.value, formData.serverErrors)))

const recaptchaEl = _ref<VueRecaptcha>()

function resetRecaptcha () {
  try {
    recaptchaEl.value?.reset()
  }
  catch { } // NOTE: We will notify the user of this error using a flash message.
}

watchEffect(() => {
  if (errors.value)
    resetRecaptcha()
})

function clearRecaptcha () {
  form.onInputValueChange(__props.field, '')
}

watch(() => [form.submitting.value], ([submitting]) => {
  resetRecaptcha()
})

const listeners = {
  async verify (value: string) {
    form.onInputValueChange(__props.field, value)

    if (invisible.value) return await form.submit()
  },
  expired: clearRecaptcha,
  error: clearRecaptcha,
}

defineOptions({
  inheritAttrs: false,
})
</script>

<template>
  <div v-if="!isTest && !invisible" class="form-input">
    <VueRecaptcha ref="recaptchaEl" :sitekey="recaptchaCheckboxSiteKey" v-on="listeners"/>
    <V2InputError v-if="errors" class="mt-1.5 -mb-2" :size="form.size || 'small'" :errors="errors"/>
  </div>
  <GInput v-else-if="isTest && !invisible" :field="field" :label="false" required/>
  <slot v-else-if="isTest"/>
  <VueRecaptcha v-else ref="recaptchaEl" :sitekey="recaptchaInvisibleSiteKey" v-on="listeners">
    <slot/>
  </VueRecaptcha>
</template>
